
let page = document.location.href,
	navbtn = $('.navbar-toggler');

navbtn.on('click', function () {
	$('.main-nav-bg').toggleClass('mobile-showing');
});


$(document).ready(function () {
	let waitinglist_form = $(".contact-form");
	let form_response = $("#ep_form_response");

	function loadCaptcha() {
		grecaptcha.render('captcha-render', {
			'sitekey': '6LfqTnAUAAAAANeKiPa58SyHchd7-MFRkMIBvcbd',
			'callback': checkCaptcha
		});
	}

	function checkCaptcha(token) {
		$('.g-recaptcha-response').val(token);
	}

	window.loadCaptcha = loadCaptcha;
	// captcha init
	const captcha_url = "https://www.google.com/recaptcha/api.js?onload=loadCaptcha&render=explicit";
	$.getScript(captcha_url);

	$(".nav-item .nav-link, .hero-cta, .hero_down").click(function (e) {
		e.preventDefault();
		let anchor = $(this).attr("href");
		$('html, body').animate({
			scrollTop: $(anchor).offset().top - 65
		}, 1000);
	});
	waitinglist_form.on("submit", function (e) {
		// client side validation
		debugger;

		$(this).addClass("was-validated");
		let captcha_response = $('.g-recaptcha-response').val();
		form_response.text("").hide();
		if (waitinglist_form[0].checkValidity() === false) {
			e.preventDefault();
			e.stopPropagation();
			form_response.text("Please fix the errors below and try submitting again.").show();
		}

		if (captcha_response.length === 0) {
			$("#captcha-error").slideDown();
			e.preventDefault();
			e.stopPropagation();
		} else {
			$("#captcha-error").slideUp();
		}
	});

	// $(document).on('click', '.floor-plans img', (e) => {
	// 	let title = $(e.target).data('fp-title'),
	// 		fp_img = `<img class="modal-fp-render" style="max-width:100\%;" src="${$(e.target).attr('src')}" alt="Enclave at The Legacy Floor Plan" />`,
	// 		pdf_link = $(e.target).attr('src').replace("floorplans/", "floorplans/pdf/").replace(".jpg", ".pdf");
	// 	$('#CSPFPInfo .modal-body .modal-fp-img').empty().append(fp_img);
	// 	$('.dl-pdf').attr("href", pdf_link);
	// 	$('#CSPFPInfo').modal('show');
	// });

	$('#frmSubmit').on('click', '#closeModal', function () {
		$('#frmSubmit').hide().removeClass('show').attr('aria-hidden', 'true');
	});

	const container = document.getElementById("myCarousel");
	const options = {
		infinite: true,
		transition: 'classic',
		Dots: false,
		Thumbs: {
			type: "classic",
		},
	};
	new Carousel(container, options, { Thumbs });

	const container_floorplans = document.getElementById("floorplansCarousel");
	const options_floorplans = {
		infinite: true,
		transition: 'classic',
		Dots: true,
		slidesPerPage: 2,
		Thumbs: {
			type: "none",
		},
	};
	new Carousel(container_floorplans, options_floorplans, { Thumbs });

	Fancybox.bind('[data-fancybox="gallery"]', {
		Thumbs: {
			type: "classic",
		},

	});
	Fancybox.bind('[data-fancybox="floorplans"]', {
		Thumbs: {
			type: "none",
		},

	});
	Fancybox.bind('.virtuals',
		{
			type: "iframe",
			allow: "autoplay fullscreen",
			scrolling: "auto",
			fitToView: true,
			padding: 0,
			"css": {
				"width": "100%",
				"height": "100%",
				"border": "0"
			},
			Thumbs: {
				type: "none",
			},
			Html: {
				autoSize: false,
			},
			on: {
				reveal: (fancybox, slide) => {
					// The content of this slide is loaded and ready to be revealed
					slide.contentEl.style.padding = "0";
					slide.contentEl.style.height = "100%";
					slide.contentEl.style.width = "100%";
				},
			},

		});

	// let $waitinglist_form = $(".contact-form");
	// let $form_response = $("#ep_form_response");
	// let $submitBtn = $waitinglist_form.find('button[type="submit"]');

	// $waitinglist_form.on("submit", function (e) {

	// 	e.preventDefault();


	// 	var formData = {
	// 		nameContactNP: $("#nameContactNP").val(),
	// 		emailContactNP: $("#emailContactNP").val(),
	// 		phoneContactNP: $("#phoneContactNP").val(),
	// 		addressStreet: $("#addressStreet").val(),
	// 		addressCity: $("#addressCity").val(),
	// 		addressState: $("#addressState").val(),
	// 		addressZIP: $("#addressZIP").val(),
	// 		Message: $("#Message").val(),
	// 	};

	// 	$.ajax({
	// 		type: "POST",
	// 		url: "formhandler.php",
	// 		data: formData,
	// 		dataType: "json",
	// 		encode: true,
	// 	}).done(function (data) {
	// 		console.log(data);
	// 		if (data.success) {

	// 		} else {
	// 		}
	// 	});
	// });


}); 